import "./Projects.css";
import Project from "./Project";
import React from "react";
import PokemonApp from "../../img/PokemonApp.jpg";
import Dashboard from "../../img/Dashboard.jpg";
import SoundSensorApp from "../../img/SoundSensorApp.jpg";
import Potion from "../../img/Potion.jpg";
import Flowchart from "../../img/Flowchart.jpg";

const Projects = () => {

    return (
        <section className="projects__section">
            <div class="card-grid">
                <Project 
                    title="Pokemon App" 
                    type="School assignment"
                    time="1 week"
                    skill="React"
                    skill2="HTML"
                    skill3="CSS" 
                    link="https://github.com/JamieVos991/Pokemon-React"
                    desc="In this Pokemon-themed project, players have the opportunity to embark on thrilling battles with various wild Pokemon they encounter. As the battle progresses and the Pokemon's health gradually decreases, the player's chance of capturing the Pokemon grows significantly. This adds an element of strategy and excitement, as players must carefully consider whether to continue battling to weaken the Pokemon further or attempt to capture it as soon as the odds are in their favor." 
                    fa1="fa-brands fa-css3" 
                    fa2="fa-brands fa-html5" 
                    fa3="fa-brands fa-react" 
                    img={PokemonApp} />

                <Project 
                    title="Politie ZO" 
                    type="Home assignment"
                    time="5 weeks"
                    skill="HTML"
                    skill2="CSS"
                    skill3="JS"
                    link="https://github.com/JamieVos991/MadeByMA"
                    desc="Embarking on my very first project with a client was an exhilarating experience that I will always cherish. Alongside a diverse group of fellow students, none of whom were web developers, we set out on a mission to create a compelling initiative aimed at influencing people about the potential consequences of making a drill rap video. This project held immense importance as it sought to address crucial social issues and raise awareness about the impact of such content on individuals and communities."
                    fa1="fa-brands fa-css3" 
                    fa2="fa-brands fa-html5" 
                    fa3="fa-brands fa-js" 
                    img="https://live.staticflickr.com/65535/48868462088_5edc95413b_b.jpg"/>

                <Project 
                    title="Pokemon Dashboard"
                    type="School assignment" 
                    time="1 week"
                    skill="React"
                    skill2="HTML"
                    skill3="CSS" 
                    link="https://github.com/JamieVos991/Dashboard-React"
                    desc="I'm delighted to share with you my latest app, a feature-rich and user-friendly creation that allows users to manage their items seamlessly. With this app, you can effortlessly add, edit, and remove items, providing a streamlined and organized way to keep track of your belongings or any other essential data."
                    fa1="fa-brands fa-css3" 
                    fa2="fa-brands fa-html5" 
                    fa3="fa-brands fa-react" 
                    img={Dashboard}/>

                <Project 
                    title="Sound sensor App" 
                    type="School assignment"
                    time="8 weeks"
                    skill="C++"
                    skill2="Scrum"
                    skill3="Git" 
                    link="https://github.com/JamieVos991/Input-Output-M7"
                    desc="
                    I am absolutely thrilled about my LED assignment, where I have the opportunity to create a remote-controlled LED strip using a NodeMCU. This exciting project will allow users to change the LED strip's colors through a web server, offering convenience and versatility in customizing the ambiance of any space."
                    fa2="fa-brands fa-c" 
                    img={SoundSensorApp}/>

                <Project 
                    title="Arcade Mechanics" 
                    type="Home assignment"
                    time="8 weeks"
                    skill="Unity"
                    skill2="C#"
                    skill3="Git" 
                    link="https://github.com/JamieVos991/Arcade-Mechanics-M3"
                    desc="I can't help but get excited when I think about the incredible journey I embarked on while working on this project! Collaborating with two other talented friends, we set out to create a thrilling Space Invaders knock-off that would capture the spirit of the classic game while infusing it with our own unique touches.

                    The project proved to be a turning point in my learning journey as it marked my introduction to the world of Unity and C#. Stepping into the realm of game development was a dream come true, and Unity's powerful capabilities allowed us to bring our imaginations to life on the digital canvas. The sheer potential of the engine motivated us to push our boundaries, and we knew we were in for an exhilarating ride."
                    fa1="fa-brands fa-unity" 
                    fa2="fa-brands fa-c" 
                    fa3="fa-brands fa-trello" 
                    img={Potion}/>

                <Project 
                    title="Collezione" 
                    type="School assignment"
                    time="12 weeks"
                    skill="React"
                    skill2="HTML"
                    skill3="SCSS"
                    link="https://github.com/JamieVos991/Collezione-React"
                    desc="Taking on the project of creating a Collezione dedicated to Jordan shoes was both thrilling and daunting, especially since it was my first time working with React. However, I was eager to embrace the challenge and dive headfirst into the world of front-end development."
                    fa1="fa-brands fa-react" 
                    fa2="fa-brands fa-html5" 
                    fa3="fa-brands fa-sass" 
                    img="https://i.pinimg.com/564x/ec/2f/9e/ec2f9e23a043375c8c5b735f992cf22a--michael-jordan-michael-okeefe.jpg"/>

                <Project 
                    title="Hello You" 
                    type="School assignment"
                    time="2 weeks"
                    skill="Python"
                    skill2="Git"
                    skill3="Scrum"
                    link="https://github.com/JamieVos991/Hello-You-M1"
                    desc="Working closely with Mediacollege Amsterdam as our client, we set out on a mission to create an application that would extend a warm welcome and provide much-needed comfort to refugees in the country they are staying. The prospect of using technology to contribute to such a noble cause filled me with both excitement and a profound sense of responsibility."
                    fa2="fa-brands fa-python" 
                    img={Flowchart}/>

                <Project 
                    title="Transformers community" 
                    type="School assignment"
                    time="8 weeks"
                    skill="Laravel"
                    skill2="Php"
                    skill3="Git"
                    link="https://github.com/JamieVos991/PROG-Team-M5"
                    desc="The Transformers community holds a special place in my heart, as it is a company that truly understands the importance of mental health and offers vital support to those who may be going through difficult times. Life can be challenging, and at some point, we all experience moments when everything seems overwhelming. But in the face of adversity, the Transformers community reminds us that it's okay to struggle and that seeking help is a sign of strength, not weakness."
                    fa1="fa-brands fa-php" 
                    fa2="fa-brands fa-css3" 
                    fa3="fa-brands fa-html5" 
                    img="https://d1fdloi71mui9q.cloudfront.net/BjJDjS8yQ6WnGSODhM1A_46t7s8wpvrliKYH8"/>
                    
            </div>
        </section>

    );
}

export default Projects;